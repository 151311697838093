import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    state: 'idle', 
    error: null, 
    response: {},
    open: false,
    clientCart: null,
    materialCode: null,
    clientData: null,
}

const refreshState = {
    state: 'idle', 
    error: null, 
    response: {},
    open: false,
    materialCode: null,
}

const shoppingCartMaterialModal = createSlice({
    name: "shoppingCartMaterialModal",
    initialState,
    reducers: {
        setCartOpen: (state, action) => {
            state.open = action.payload;
        },
        setClientCart: (state, action) => {
            state.clientCart = action.payload;
        },
        setMaterialCode: (state, action) => {
            state.materialCode = action.payload;
        },
        setClientData: (state, action) => {
            state.clientData = action.payload;
        },
        resetCart: () => refreshState,
    }
});

export const { setCartOpen, setClientCart, setMaterialCode, setClientData, resetCart } = shoppingCartMaterialModal.actions;
export default shoppingCartMaterialModal.reducer